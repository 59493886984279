<template>
<div class="flex flex-column flex-auto">
    <div class="p-5">
        <div class="grid">
            <!-- Filtered URLs -->
            <Accordion :activeIndex="-1" class="col-12">
                <AccordionTab header="Filtered URLs">
                    <div v-if="filteredLinks === null || Object.keys(filteredLinks).length === 0">
                        <p>No data filtered links found for this search</p>
                    </div>
                    <div v-else>
                        <div v-for="(movie, key) in filteredLinks" :key="key">
                            <h3 v-tooltip.right="'These results are a summary only, showing 500 urls. Some results may therefore be missing.'">
                                {{ key }} <i class="pi pi-info-circle"/>
                            </h3>
                            <DataTable v-model:filters="filteredLinksFilter" :value="movie" scrollable scrollHeight="400px" paginator :rows="20"
                                       filterDisplay="row" :globalFilterFields="['filter']">
                                <Column field="filter" header="Filter" :showFilterMenu="false">
                                    <template #filter="{ filterModel, filterCallback }">
                                        <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="filterNames"
                                                  placeholder="Select a Filter" class="p-column-filter"
                                                  style="min-width: 14rem">
                                        </Dropdown>
                                    </template>
                                </Column>
                                <Column field="link" header="Link"/>
                            </DataTable>
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>

            <!-- Hard Exclude Section -->
            <div class="col-12">
                <div class="surface-card shadow-2 border-round p-3">
                    <h3>New Hard Exclude Filters</h3>
                    <Textarea v-model="newHardExcludes" placeholder="Enter a hard exclude domain" class="w-full mb-3" rows="5"/>
                </div>
            </div>

            <!-- Single Removal Section -->
            <div class="col-12">
                <div class="surface-card shadow-2 border-round p-3">
                    <h3>Single Links For Removal</h3>
                    <Textarea v-model="singleLinks" placeholder="Enter a link" class="w-full mb-3" rows="5"/>
                </div>
            </div>

            <!-- Ai Enhancement -->
            <Accordion :activeIndex="-1" class="col-12">
                <AccordionTab>
                    <template #header>
                        <span>AI Options</span>
                    </template>
                    <!-- Show AI URLs in main results -->
                    <Button :class="showAIUrls ? '' : 'p-button-outlined'" class="w-full" label="AI Urls" :icon="showAIUrls ? 'pi pi-eye' : 'pi pi-eye-slash'" @click="showAIUrls=!showAIUrls" />
                </AccordionTab>

                <AccordionTab>
                    <template #header>
                        <span>AI Approved</span>
                    </template>
                    <TabView v-model:activeIndex="activeIndex">
                        <TabPanel v-for="(movie, key) in allAssetLinks" :key="key" :header="key">
                            <div v-show="fetchedAllData" class="grid">
                                <!-- Good links -->
                                <div class="col-12">
                                    <div v-if="allAssetLinks[key] === null || allAssetLinks[key].length === 0">
                                        <p>No successful links found for this search</p>
                                    </div>
                                    <div v-else>
                                        <div v-for="assetLink in allAssetLinks[key]" :key="assetLink.link">
                                            <div v-if="assetLink.approved" class="w-full" style="word-wrap: break-word;" @contextmenu="onRightClick" aria-haspopup="true">
                                                {{ assetLink.link }}
                                            </div>
                                            <Divider v-if="assetLink.approved"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                </AccordionTab>

                <AccordionTab>
                    <template #header>
                        <span :class="potentialFalseNegatives !== null && potentialFalseNegativeCount > 0 ? 'fadein animation-duration-1000 animation-iteration-infinite' : ''">AI Suggested</span>
                    </template>

                    <TabView v-model:activeIndex="activeIndex">
                        <TabPanel v-for="(movie, key) in potentialFalseNegatives" :key="key" :header="key">
                            <div v-show="fetchedAllData" class="grid">
                                <!-- Potential false negatives links -->
                                <div class="col-6">
                                    <div v-if="potentialFalseNegatives[key] === null || potentialFalseNegatives[key].length === 0">
                                        <p>No potential false negatives found for this search</p>
                                    </div>
                                    <div v-else>
                                        <div v-for="filteredLink in potentialFalseNegatives[key]" :key="filteredLink.link">
                                            <div class="w-full" style="word-wrap: break-word;" @contextmenu="onRightClickPFN" aria-haspopup="true">
                                                {{ filteredLink.link }}
                                            </div>
                                            <Divider/>
                                        </div>
                                    </div>
                                </div>

                                <!-- False negatives links -->
                                <div class="col-6">
                                    <div v-if="falseNegatives[key] === null || falseNegatives[key].length === 0">
                                        <p>No false negatives approved for this search</p>
                                    </div>
                                    <div v-else>
                                        <div v-for="falseNegative in falseNegatives[key]" :key="falseNegative.link">
                                            <div class="w-full" style="word-wrap: break-word;" @contextmenu="onRightClickFN" aria-haspopup="true">
                                                {{ falseNegative.link }}
                                            </div>
                                            <Divider/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                </AccordionTab>
            </Accordion>

            <!-- Asset Specific Filtering -->
            <div class="col-12">
                <div class="surface-card shadow-2 border-round p-3">
                    <h3>Asset Specific Filtering</h3>
                    <TabView v-model:activeIndex="activeIndex">
                        <TabPanel v-for="(movie, key) in allAssetLinks" :key="key" :header="key">
                            <div v-show="fetchedAllData" class="grid">
                                <h3 class="w-full my-2">{{ key }} Specific Filters</h3>

                                <!-- Suggested Keywords -->
                                <span class="w-full mt-0">Suggested Keywords</span>
                                <div class="col-12">
                                    <div v-if="wordFrequency[key] === null || wordFrequency[key] === undefined || wordFrequency[key].length === 0">
                                        <p>No suggested keywords found for this search</p>
                                    </div>
                                    <perfect-scrollbar v-else class="flex flex-wrap overflow-y-scroll" style="max-height: 15rem">
                                        <div v-for="word in wordFrequency[key]" :key="word.word" class="px-2 py-1">
                                            <!-- New filters -->
                                            <div v-if="word.frequency>0" class="px-1 py-0 border-1 border-orange-200 border-round flex flex-wrap align-items-center bg-orange-100 cursor-pointer h
                                                hover:bg-green-100 hover:border-green-200 p-ripple" @click="addAssetSpecific(word)" v-tooltip.top="{ value: word.samples, showDelay: 2000, hideDelay: 300 }">
                                                <div class="text-700 font-bold pr-2">
                                                    {{ word.word }}
                                                </div>
                                                <div class=" bg-white circle-border">
                                                    {{ word.frequency }}
                                                </div>
                                            </div>
                                            <!-- Existing filters -->
                                            <div v-else class="px-1 py-0 border-1 border-blue-200 border-round flex flex-wrap align-items-center bg-blue-100 cursor-pointer h
                                                hover:bg-green-100 hover:border-green-200 p-ripple" @click="addAssetSpecific(word)" v-tooltip.top="{ value: word.samples, showDelay: 2000, hideDelay: 300 }">
                                                <div class="text-700 font-bold pr-2">
                                                    {{ word.word }}
                                                </div>
                                                <div class=" bg-white circle-border">
                                                    !
                                                </div>
                                            </div>
                                        </div>
                                    </perfect-scrollbar>
                                </div>

                                <!-- Asset Specific Filters -->
                                <span class="w-full mt-0">Asset Specific Filters</span>
                                <div class="col-12">
                                    <div v-if="acceptedKeywords[key] === null || acceptedKeywords[key] === undefined || acceptedKeywords[key].length === 0">
                                        <p>No asset specific filters found for this search</p>
                                    </div>
                                    <perfect-scrollbar v-else class="flex flex-wrap overflow-y-scroll" style="max-height: 15rem">
                                        <div v-for="filter in acceptedKeywords[key]" :key="filter.word" class="px-2 py-1">
                                            <!-- New filters -->
                                            <div v-if="filter.frequency>0" class="px-1 py-0 border-1 border-green-200 border-round flex flex-wrap align-items-center bg-green-100 cursor-pointer
                                                hover:bg-orange-100 hover:bg-orange-200 p-ripple" @click="removeAssetSpecific(filter)" v-tooltip.top="{ value: filter.samples, showDelay: 2000, hideDelay: 300 }">
                                                <div class="text-700 font-bold pr-2">
                                                    {{ filter.word }}
                                                </div>
                                                <div class=" bg-white circle-border">
                                                    {{ filter.frequency }}
                                                </div>
                                            </div>
                                            <!-- Existing filters -->
                                            <div v-else class="px-1 py-0 border-1 border-blue-200 border-round flex flex-wrap align-items-center bg-blue-100 cursor-pointer
                                            hover:bg-orange-100 hover:bg-orange-200 p-ripple" @click="removeAssetSpecific(filter)" v-tooltip.top="{ value: filter.samples, showDelay: 2000, hideDelay: 300 }">
                                                <div class="text-700 font-bold pr-2">
                                                    {{ filter.word }}
                                                </div>
                                                <div class=" bg-white circle-border">
                                                    !
                                                </div>
                                            </div>
                                        </div>
                                    </perfect-scrollbar>
                                </div>

                                <InputText v-model="assetSpecificFilters[key]" placeholder="Enter a filter" class="w-full my-3" :disabled="true"/>
                                <!-- Good links -->
                                <div class="col-6 border-right-1 border-300">
                                    <h4>Asset Links</h4>
                                    <div v-if="allAssetLinks[key] === null || allAssetLinks[key].length === 0">
                                        <p>No successful links found for this search</p>
                                    </div>
                                    <div v-else>
                                        <div v-for="assetLink in allAssetLinks[key].slice(first, first + rows)" :key="assetLink.id">
                                            <div v-if="!assetLink.approved || (showAIUrls && assetLink.approved)" class="w-full" style="word-wrap: break-word;" @contextmenu="onRightClick" aria-haspopup="true">
                                                {{ assetLink.link }}
                                            </div>
                                            <Divider v-if="!assetLink.approved || (showAIUrls && assetLink.approved)"/>
                                        </div>
                                    </div>
                                </div>

                                <!-- Filtered links -->
                                <div class="col-6">
                                    <h4>Filtered Links</h4>
                                    <div v-if="newFilteredLinks === null || newFilteredLinks[key] === []">
                                        <p>No links filtered for this search</p>
                                    </div>
                                    <div v-else>
                                        <div v-for="filteredLink in newFilteredLinks[key].slice(first, first + rows)" :key="filteredLink.id">
                                            <Highlighter class="w-full" style="word-wrap: break-word;"
                                                         highlightClassName="highlight"
                                                         :searchWords="assetSpecificFilters[key].split(',').concat(newHardExcludes.split('\n')).concat(singleLinks.split('\n'))"
                                                         :autoEscape="true"
                                                         :textToHighlight="filteredLink.link"/>
                                            <Divider />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <Paginator v-model:first="this.first" :rows="this.rows" :totalRecords="totalUrls(key)" :rowsPerPageOptions="[100, 1000, 2500, 5000]"/>
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
            </div>

            <!-- Submit Button -->
            <div class="col-12">
                <div class="surface-card shadow-2 border-round p-3">
                    <Button label="Submit" class="w-full" @click="submitConfirmation"/>
                </div>
            </div>
        </div>
    </div>
</div>
<ContextMenu ref="contextMenu" :model="contextMenuItems"/>
<ContextMenu ref="contextMenuPFN" :model="contextMenuPFNItems"/>
<ContextMenu ref="contextMenuFN" :model="contextMenuFNItems"/>
</template>

<script>
import {FilterMatchMode} from "primevue/api";
import Highlighter from 'vue-highlight-words'

export default {
    name: "DynamicFiltering",
    components: {
        Highlighter,
    },

    data() {
        return {
            loadingFiltered: false,
            loadingResults: false,
            loadingSpecific: false,
            loadingPFN: false,

            fetchedFiltered: false,
            fetchedResults: false,
            fetchedSpecific: false,
            fetchedPFN: false,

            // Data for the search
            searchId: parseInt(this.$route.params.searchId),
            overviewData: null,
            countryData: null,
            crawledLinks: null,
            filteredLinks: null,
            filterNames: null,
            assetLinks: null,

            runby: "",
            rundate: "",
            subby: "",
            subdate: "",

            filteredLinksFilter: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                filter: {value: null , matchMode: FilterMatchMode.EQUALS }
            },

            originalAllAssetLinks: null,
            allAssetLinks: null,
            newFilteredLinks: null,
            potentialFalseNegatives: null,

            // Pagination
            first: 0,
            rows: 5000,

            // Data for filtering
            activeIndex: 0,
            newHardExcludes: "",
            assetSpecificFilters: {},
            singleLinks: "",

            // Right click menu
            contextMenuItems: [
                { label: 'Specific Filter', icon: 'pi pi-fw pi-search', command: () => this.addSpecificFilter()},
                { label: 'Hard Exclude', icon: 'pi pi-fw pi-trash', command: () => this.addHardExclude()},
                { label: 'Remove Single', icon: 'pi pi-fw pi-search', command: () => this.removeSingle()}
            ],
            contextMenuPFNItems: [
                { label: 'Approve', icon: 'pi pi-fw pi-check', command: () => this.approveSinglePFN()},
            ],
            contextMenuFNItems: [
                { label: 'Remove', icon: 'pi pi-fw pi-check', command: () => this.removeSingleFN()},
            ],
            lastClickedLink: null,

            // AI Enhancement
            showAIUrls: false,
            falseNegatives: {},

            // Suggested asset specific
            suggestedKeywords: [],
            wordFrequency: {},
            acceptedKeywords: {},
        }
    },

    computed: {
        fetchedAllData() {
            return this.fetchedFiltered && this.fetchedResults && this.fetchedSpecific && this.fetchedPFN;
        },
        potentialFalseNegativeCount() {
            let count = 0;
            for (let key in this.potentialFalseNegatives) {
                count += this.potentialFalseNegatives[key].length;
            }
            return count;
        },
    },

    mounted() {
        this.getSearch();
        this.getAssetLinks();
        this.getAssetSpecificFilters();
        this.getPotentialFalseNegatives();
    },

    methods: {
        getSearch() {
            this.loadingFiltered = true;
            this.$factory.searchPage.getSearchMetrics(this.searchId).then((response) => {
                return response
            }).then((data) => {
                // If submitted then redirect to the results page
                if (data.overview[0].subby !== '') {
                    this.$emitter.emit('bad-toast', "This search has already been submitted");
                    this.$router.push({name: 'Searches'})
                }

                this.overviewData = data.overview;
                this.runby = this.overviewData[0].runby;
                this.rundate = this.overviewData[0].rundate;
                this.subby = this.overviewData[0].subby;
                this.subdate = this.overviewData[0].subdate;

                this.countryData = data.countries;
                this.filteredLinks = data.filtered;
                this.filterNames = data.filternames;

                this.assetLinks = data.assetlinks;

                this.crawledLinks = {};
                this.overviewData.forEach((item) => {
                    this.crawledLinks[item.name] = [].concat(this.filteredLinks[item.name], this.assetLinks[item.name]);
                });
                this.loadingFiltered = false;
                this.fetchedFiltered = true;
            });
        },

        getAssetLinks() {
            this.loadingResults = true;
            this.$factory.searchPage.getAllAssetLinks(this.searchId).then((response) => {
                return response
            }).then((data) => {
                this.originalAllAssetLinks = JSON.parse(JSON.stringify(data));
                this.allAssetLinks = data;

                // Create a new object with the same keys as allAssetLinks
                this.newFilteredLinks = {};
                for (const key in this.allAssetLinks) {
                    if (Object.hasOwnProperty.call(this.allAssetLinks, key)) {
                        // this.assetSpecificFilters[key] = "";
                        this.newFilteredLinks[key] = [];
                    }
                }
                this.fetchedResults = true;
                console.debug("All asset links:", this.allAssetLinks);

                this.generateSuggestedKeywords();
            }).catch((error) => {
                console.error(error);
            }).finally(() => {
                this.loadingResults = false;
            });
        },

        getPotentialFalseNegatives() {
            this.loadingPFN = true;
            this.$factory.searchPage.getPotentialFalseNegatives(this.searchId).then((response) => {
                return response
            }).then((data) => {
                console.debug("Potential false negatives:", data);
                this.potentialFalseNegatives = data;

                // Create a new object with the same keys as allAssetLinks
                this.falseNegatives = {};
                for (const key in this.potentialFalseNegatives) {
                    if (Object.hasOwnProperty.call(this.potentialFalseNegatives, key)) {
                        // this.assetSpecificFilters[key] = "";
                        this.falseNegatives[key] = [];
                    }
                }

                this.loadingPFN = false;
                this.fetchedPFN = true;
            }).catch((error) => {
                console.error(error);
            }).finally(() => {
                this.loadingPFN = false;
            });
        },

        getAssetSpecificFilters() {
            this.loadingSpecific = true;
            this.$factory.filters.getAssetSpecificFilters(this.searchId)
            .then((data) => {
                // for key in data add commas between the values within
                console.debug("Asset specific filters:", data)

                for (const [key, value] of Object.entries(data)) {
                    if (value.length === 0) {
                        this.assetSpecificFilters[key] = "";
                    } else {
                        this.assetSpecificFilters[key] = value.join(",");
                        // Add current filters to the acceptedKeywords
                        this.acceptedKeywords[key] = value.map((word) => {
                            return {word: word, frequency: -1, samples: "This is an existing filter"};
                        });
                    }
                }
                this.fetchedSpecific = true;
                console.debug(this.assetSpecificFilters)
            }).catch((error) => {
                console.error(error);
            }).finally(() => {
                this.loadingSpecific = false;
            });
        },

        onRightClick(event) {
            this.lastClickedLink = event.target.innerText;
            this.$refs.contextMenu.show(event);
        },

        onRightClickPFN(event) {
            this.lastClickedLink = event.target.innerText;
            this.$refs.contextMenuPFN.show(event);
        },

        onRightClickFN(event) {
            this.lastClickedLink = event.target.innerText;
            this.$refs.contextMenuFN.show(event);
        },

        approveSinglePFN() {
            let activeAsset = Object.keys(this.originalAllAssetLinks)[this.activeIndex];

            // Remove lastClickedLink from potentialFalseNegatives where elements are arrays
            // eslint-disable-next-line no-unused-vars
            for (const [key, value] of Object.entries(this.potentialFalseNegatives)) {
                for (let i = 0; i < value.length; i++) {
                    if (value[i].link === this.lastClickedLink) {
                        value.splice(i, 1);
                    }
                }
            }

            // Add to false negatives so that it can be added to the database
            this.falseNegatives[activeAsset].push({
                "name": activeAsset,
                "link": this.lastClickedLink,
            });

            // Add lastClickedLink to allAssetLinks
            this.allAssetLinks[activeAsset].push({
                "name": activeAsset,
                "link": this.lastClickedLink,
                "approved": true,
            });

        },

        removeSingleFN() {
            let activeAsset = Object.keys(this.originalAllAssetLinks)[this.activeIndex];

            // Add lastClickedLink to potentialFalseNegatives
            this.potentialFalseNegatives[activeAsset].push({
                "name": activeAsset,
                "link": this.lastClickedLink,
            });

            // Remove lastClickedLink from allAssetLinks
            // eslint-disable-next-line no-unused-vars
            for (const [key, value] of Object.entries(this.allAssetLinks)) {
                for (let i = 0; i < value.length; i++) {
                    if (value[i].link === this.lastClickedLink) {
                        value.splice(i, 1);
                    }
                }
            }

            // Remove lastClickedLink from falseNegatives
            // eslint-disable-next-line no-unused-vars
            for (const [key, value] of Object.entries(this.falseNegatives)) {
                for (let i = 0; i < value.length; i++) {
                    if (value[i].link === this.lastClickedLink) {
                        value.splice(i, 1);
                    }
                }
            }
        },

        addSpecificFilter() {
            let highlightedText = window.getSelection().toString();
            let activeAsset = Object.keys(this.originalAllAssetLinks)[this.activeIndex];

            // Check if the highlighted text is empty
            // Check if the domain is already in the specific filters
            // Check if the specific filters are empty
            if (highlightedText === "") {
                 return;
            } else if (this.assetSpecificFilters[activeAsset].includes(highlightedText)) {
                return;
            } else if (this.assetSpecificFilters[activeAsset] === "") {
                this.assetSpecificFilters[activeAsset] = highlightedText;
            } else {
                this.assetSpecificFilters[activeAsset] += ',' + highlightedText;
            }

        },

        addHardExclude() {
            if (this.lastClickedLink === "") { return }
            let domain = this.lastClickedLink.split("/")[2];

            // Check if the domain is already in the hard excludes
            if (this.newHardExcludes === "") {
                this.newHardExcludes = domain
                return
            } else if (this.newHardExcludes.includes(domain)) {
                return
            }

            this.newHardExcludes += '\n' + domain;
        },

        removeSingle() {
            if (this.lastClickedLink === "") { return }
            // Check if the domain is already in the single links
            if (this.singleLinks === "") {
                this.singleLinks = this.lastClickedLink
                return
            } else if (this.singleLinks.includes(this.lastClickedLink)) {
                return
            }

            this.singleLinks += '\n' + this.lastClickedLink;
        },

        applyNewFilters() {
            // Iterate all assets
            for (const activeAsset in this.originalAllAssetLinks) {
                let assetSpecificFiltersSplit = this.assetSpecificFilters[activeAsset].split(",");
                let newHardExcludesSplit = this.newHardExcludes.split("\n");
                let singleLinksSplit = this.singleLinks.split("\n");

                let assetSpecificFilterGate = assetSpecificFiltersSplit[0] !== "";
                let hardExcludesGate = this.newHardExcludes !== "";
                let singleLinksGate = this.singleLinks !== "";

                // Profile this section of code
                let start = performance.now();
                this.allAssetLinks[activeAsset] =  this.originalAllAssetLinks[activeAsset].filter((link) => {
                    let safe = true;
                    // Check if the link contains any of the asset specific filters
                    if (assetSpecificFilterGate) {
                        assetSpecificFiltersSplit.forEach((filter) => {
                            if (link.link.includes(filter)) {
                                safe = false;
                            }
                        });
                    }

                    // Check if the link contains any of the hard exclude domains
                    if (hardExcludesGate) {
                        newHardExcludesSplit.forEach((filter) => {
                            if (filter !== '' && link.link.includes(filter)) {
                                safe = false;
                            }
                        });
                    }

                    // Check if the link contains any of the single removal links
                    if (singleLinksGate) {
                        singleLinksSplit.forEach((filter) => {
                            if (filter !== '' && link.link === filter) {
                                safe = false;
                            }
                        });
                    }
                    return safe;
                });
                console.debug("Filtering links took", performance.now() - start, "ms"); // Filtering links took 56.700000047683716 ms

                start = performance.now();
                // this.newFilteredLinks[activeAsset] = this.originalAllAssetLinks[activeAsset].filter((link) => {
                //     return !this.allAssetLinks[activeAsset].includes(link);
                // });
                const activeAssetLinksSet = new Set(this.allAssetLinks[activeAsset]);
                this.newFilteredLinks[activeAsset] = this.originalAllAssetLinks[activeAsset].filter(link =>
                    !activeAssetLinksSet.has(link)
                );
                console.debug("Filtering new links took", performance.now() - start, "ms"); // Filtering new links took 240244.20000004768 ms
            }
        },

        submitConfirmation(event) {
            this.$confirm.require({
                target: event.currentTarget,
                message: 'Are you sure you want to proceed?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.submit()
                },
            });
        },

        submit() {
            console.debug("Submitting new filters");
            let listAssetSpecificFilters = {};
            for (const [key, value] of Object.entries(this.assetSpecificFilters)) {
                if (value === "") { continue }
                listAssetSpecificFilters[key] = value.split(",");
            }

            let listHardExcludes = null;
            if (this.newHardExcludes === "") {
                listHardExcludes = [];
            } else {
                listHardExcludes = this.newHardExcludes.split("\n");
            }

            let listSingleRemovals = null;
            if (this.singleLinks === "") {
                listSingleRemovals = [];
            } else {
                listSingleRemovals = this.singleLinks.split("\n");
            }

            let objFalseNegatives = {};
            for (const [key, value] of Object.entries(this.falseNegatives)) {
                if (value.length === 0) { continue }
                objFalseNegatives[key] = value.map((link) => {
                    return link.link;
                });
            }

            let submissionObject = {
                searchId: this.searchId,
                assetSpecificFilters: listAssetSpecificFilters,
                hardExcludes: listHardExcludes,
                singleRemovals: listSingleRemovals,
                falseNegatives: objFalseNegatives,
            };

            this.$factory.searchPage.putDynamicFilteringContent(submissionObject).then(() => {
                this.$emitter.emit("good-toast", "Filtering will now begin.")
                this.$router.push({name: 'Search Manager'})
            }).catch((error) => {
                console.error(error);
                this.$emitter.emit("bad-toast", "Something went wrong. Please try again later.")
            });
        },

        totalUrls(key) {
            let total = 0;
            if (this.allAssetLinks[key] !== null) { total += this.allAssetLinks[key].length }
            if (this.newFilteredLinks[key] !== null) { total += this.newFilteredLinks[key].length }
            return total;
        },

        generateSuggestedKeywords() {
            // For each asset
            // Remove the domain from every link and split the link into words
            // For the split words, also save a sample of the original link
            // Count the frequency of each word and save the word, frequency, and sample
            for (const [key, value] of Object.entries(this.allAssetLinks)) {
                let allWords = [];
                let delimiter = ['\\.', '/', '-', '_', ' ', '\\?', '=', '&', '#', '%.{2}', '!', '@', '\\$', '\\^', '\\*', '\\(', '\\)', '\\[',
                    '\\]', '\\{', '\\}', '\\|', '\\\\', ':', ';', '<', '>', ',', '\\.', '`', '~', '"', "'", '\\+'];

                for (let i = 0; i < value.length; i++) {
                    let link = value[i].link;
                    let domain = link.split("/")[2];
                    let linkWithoutDomain = link.split(domain).slice(1).join(domain); // Get the part of the link without the domain

                    let words = linkWithoutDomain.split(new RegExp(delimiter.join('|'), 'g'));
                    let sample = linkWithoutDomain;

                    allWords.push({words: words, sample: sample});
                }

                // Count the frequency of each word and save the word, frequency, and sample
                let wordFrequency = {};
                for (let i = 0; i < allWords.length; i++) {
                    for (let j = 0; j < allWords[i].words.length; j++) {
                        let word = allWords[i].words[j].toLowerCase();
                        if (word === "") { continue }
                        if (word in wordFrequency) {
                            wordFrequency[word].frequency += 1;
                            if (wordFrequency[word].samples.length < 5) {
                                // Sample as string with new line
                                wordFrequency[word].samples += "\n" + allWords[i].sample;
                            }
                        } else {
                            wordFrequency[word] = {frequency: 1, samples: allWords[i].sample};
                        }
                    }
                }

                // Convert the object to an array and sort by frequency
                this.wordFrequency[key] = Object.entries(wordFrequency).map(([key, value]) => {
                    return {word: key, frequency: value.frequency, samples: value.samples};
                }).sort((a, b) => {
                    return b.frequency - a.frequency;
                });
            }
            console.debug("Word frequency:", this.wordFrequency);
        },

        addAssetSpecific(word) {
            // Add the word to the asset specific filters
            let activeAsset = Object.keys(this.originalAllAssetLinks)[this.activeIndex];
            if (this.assetSpecificFilters[activeAsset] === "") {
                this.assetSpecificFilters[activeAsset] = word.word;
            } else {
                this.assetSpecificFilters[activeAsset] += "," + word.word;
            }
            // Add to the accepted keywords
            if (this.acceptedKeywords[activeAsset] === undefined) {
                this.acceptedKeywords[activeAsset] = [];
            }
            this.acceptedKeywords[activeAsset].push(word);

            // Sort the accepted keywords
            this.acceptedKeywords[activeAsset].sort((a, b) => {
                return b.frequency - a.frequency;
            });

            // Remove the word from the suggested keywords
            let index = this.wordFrequency[activeAsset].findIndex((item) => item.word === word.word);
            this.wordFrequency[activeAsset].splice(index, 1);
        },

        removeAssetSpecific(word) {
            // Remove the word from the asset specific filters
            let activeAsset = Object.keys(this.originalAllAssetLinks)[this.activeIndex];
            let assetSpecificFiltersSplit = this.assetSpecificFilters[activeAsset].split(",");
            let index = assetSpecificFiltersSplit.findIndex((item) => item === word.word);
            assetSpecificFiltersSplit.splice(index, 1);
            this.assetSpecificFilters[activeAsset] = assetSpecificFiltersSplit.join(",");

            // Remove the word from the accepted keywords
            index = this.acceptedKeywords[activeAsset].findIndex((item) => item.word === word.word);
            this.acceptedKeywords[activeAsset].splice(index, 1);

            // Add the word back to the suggested keywords
            this.wordFrequency[activeAsset].push(word);

            // Resort the word frequency
            this.wordFrequency[activeAsset].sort((a, b) => {
                return b.frequency - a.frequency;
            });
        },
    },

    watch: {
        fetchedAllData: {
            handler: function () {
                if (!this.fetchedAllData) { return }
                this.applyNewFilters();
            },
            deep: true,
        },

        newHardExcludes: {
            handler: function () {
                if (!this.fetchedAllData) { return }
                if (
                    this.originalAllAssetLinks === null ||
                    Object.keys(this.originalAllAssetLinks).length === 0
                ) { return }
                this.applyNewFilters();
            },
            deep: true,
        },

        singleLinks: {
            handler: function () {
                if (!this.fetchedAllData) { return }
                if (
                    this.singleLinks === "" ||
                    this.originalAllAssetLinks === null ||
                    Object.keys(this.originalAllAssetLinks).length === 0
                ) { return }
                this.applyNewFilters();
            },
            deep: true,
        },

        assetSpecificFilters: {
            handler: function () {
                if (!this.fetchedAllData) { return }
                if (
                    this.originalAllAssetLinks === null ||
                    Object.keys(this.originalAllAssetLinks).length === 0
                ) { return }
                this.applyNewFilters();
            },
            deep: true,
        },

        suggestedKeywords: {
            handler: function () {
                if (!this.fetchedAllData) {
                    return
                }
                if (
                    this.originalAllAssetLinks === null ||
                    Object.keys(this.originalAllAssetLinks).length === 0
                ) {
                    return
                }
                // Resort the suggested keywords
                this.generateSuggestedKeywords();
            },
            deep: true,
        },
    },
}
</script>

<style scoped>
.circle-border {
    -moz-border-radius: 30px; /* or 50% */
    border-radius: 30px; /* or 50% */
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

:deep(.tooltip .ui-tooltip-text) {
    width: 10rem;
}
</style>
